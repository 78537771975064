<template>
  <div 
    class="slider-range"   
    aria-hidden="true"
    :class="pricesRange.isInput && 'slider-range-new'"
  >
    <div
      v-if="pricesRange.isInput"
      class="slider-range__input"
      :class="{ 'slider-range__input-rtl': GB_cssRight }"
    >
      <div
        class="slider-range__input-left"
        :class="dynamicMinPrice == minPrice && 'slider-range__input-default'"
      >
        <span>{{ `${pricesRange?.language?.min}:` }}</span>
        <input
          v-model="dynamicMinPrice"
          type="number"
          :min="minPrice"
          :max="maxPrice"
          @change="inputChange"
        />
      </div>
      <div class="slider-range__input-to">
        {{ pricesRange?.language?.to }}
      </div>
      <div
        class="slider-range__input-right"
        :class="dynamicMaxPrice == maxPrice && 'slider-range__input-default'"
      >
        <span>{{ `${pricesRange?.language?.max}:` }}</span>
        <input
          v-model="dynamicMaxPrice"
          type="number"
          :min="minPrice"
          :max="maxPrice"
          @change="inputChange"
        />
      </div>
    </div>
    <div class="slider-range__prices">
      <div
        ref="priceRangeLower"
        class="slider-range__prices-lower"
      >
        {{ priceLower }}
      </div>
      <div
        ref="priceRangeUpper"
        class="slider-range__prices-upper"
      >
        {{ priceUpper }}
      </div>
    </div>
    <div class="slider-range__wrapper">
      <div
        ref="sliderEle"
        class="apollo-slider"
      ></div>
    </div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

const { lang, currency, currencies, GB_cssRight } = gbCommonInfo

export default {
  name: 'SliderRange',
  props: {
    /**
     * @param {Number} minPrice 最小价格（范围）
     * @param {Number} maxPrice 最大价格（范围）
     * @param {Number} startMinPrice 当前滑块在的最小价格
     * @param {Number} startMaxPrice 当前滑块在的最大价格
     * @param {Object} language 多语言【To/Min/Max】
     * @param {Boolean} isInput 价格筛选优化【pageId=1190966650】
     */
    pricesRange: {
      type: Object,
      default: () => ( {} )
    }
  },
  data () {
    return {
      currency,
      GB_cssRight,
      sliderEle: null,
      dynamicMinPrice: '',
      dynamicMaxPrice: '',
      priceInput: '', // slide/keyboard
      currencySymbol: { symbol_left: '', symbol_right: '' }
    }
  },
  computed: {
    minPrice () {
      return +this.pricesRange.minPrice
    },
    maxPrice () {
      return +this.pricesRange.maxPrice
    },
    priceLower () {
      const { symbol_left, symbol_right } = this.currencySymbol

      const price = this.pricesRange.isInput
        ? GB_cssRight ? this.maxPrice : this.minPrice
        : GB_cssRight ? this.dynamicMaxPrice : this.dynamicMinPrice

      return `${ symbol_left }${ price }${ symbol_right }`
    },
    priceUpper () {
      const { symbol_left, symbol_right } = this.currencySymbol
      
      const price = this.pricesRange.isInput
        ? GB_cssRight ? this.minPrice : this.maxPrice
        : GB_cssRight ? this.dynamicMinPrice : this.dynamicMaxPrice

      return `${ symbol_left }${ price }${ symbol_right }`
    }
  },
  watch: {
    pricesRange: {
      handler () {
        if (this.clickSelf) return this.clickSelf = false

        const { minPrice, maxPrice, startMinPrice = '', startMaxPrice = '' } = this.pricesRange
        if (minPrice !== maxPrice) {
          if (currencies && currencies[lang] && currencies[lang][this.currency]) {
            const currencie = currencies[lang][this.currency]
            this.currencySymbol['symbol_left'] = currencie['symbol_left'] || ''
            this.currencySymbol['symbol_right'] = currencie['symbol_right'] || ''
          }

          this.$nextTick(() => {
            this.sliderEle = this.$refs.sliderEle
            let startMin = minPrice 
            let startMax = maxPrice
            if (String(startMinPrice) && String(startMaxPrice)) {
              startMin = startMinPrice
              startMax = startMaxPrice
            }
            this.dynamicMinPrice = +startMin
            this.dynamicMaxPrice = +startMax
            if (!this.sliderEle.noUiSlider) {
              this.initSlider()
            } else {
              this.updateSlider()
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    initSlider () {
      noUiSlider.create(this.sliderEle, {
        start: [this.dynamicMinPrice, this.dynamicMaxPrice],
        behaviour: 'none',
        connect: true,
        direction: this.GB_cssRight ? 'rtl' : 'ltr',
        range: {
          min: this.minPrice,
          max: this.maxPrice
        },
        cssPrefix: 'aprs-',
        format: wNumb({
          decimals: 0
        })
      })
      this.sliderEle.noUiSlider.on('end', values => {
        this.priceInput = 'slide'
        this.changePrice(values)
      })
      
      this.sliderEle.noUiSlider.on('update', (values, handle) => {
        handle
          ? this.dynamicMaxPrice = +values[handle]
          : this.dynamicMinPrice = +values[handle]
      })
    },
    changePrice (values) {
      if (+values[0] == this.lastMinPrice && values[1] == this.lastMaxPrice) return

      this.clickSelf = true

      this.lastMinPrice = +values[0]
      this.lastMaxPrice = +values[1]

      this.$emit('priceChange', {
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        priceInput: this.priceInput,
        startMinPrice: this.dynamicMinPrice,
        startMaxPrice: this.dynamicMaxPrice
      })
    },
    updateSlider () {
      this.sliderEle.noUiSlider.updateOptions({
        start: [this.dynamicMinPrice, this.dynamicMaxPrice],
        range: {
          min: this.minPrice,
          max: this.maxPrice,
        },
      })
    },
    reset () { 
      this.resetSlider()
      this.changePrice([this.minPrice, this.maxPrice])
    },
    resetSlider () {
      this.sliderEle?.noUiSlider.set([this.minPrice, this.maxPrice])
    },
    inputChange () {
      this.updateSlider()
      this.priceInput = 'keyboard'
      this.changePrice([this.dynamicMinPrice, this.dynamicMaxPrice])
    }
  }
}
</script>

<style lang="less">
[disabled] .aprs-connect {
    background-color: #ccc;
}

[disabled].aprs-target,
[disabled].aprs-handle,
[disabled] .aprs-handle {
    cursor: not-allowed;
}

.aprs-horizontal {
    height: 2px;
    width: 96%;
}

.aprs-horizontal .aprs-handle {
    width: 0.6rem;
    height: 0.6rem;
    right: -0.3rem /*rtl:ignore*/;
    border-radius: 50%;
    top: -0.3rem;
}

.noUi-touch-area {
    height: 100%;
    width: 100%;
}

.aprs-tooltip {
  display: block;
  position: absolute;
  color: #000;
  text-align: center;
  white-space: nowrap;
}

.aprs-horizontal {
  .aprs-tooltip {
    transform: translate(-50%, 0) /*rtl:ignore*/;
    left: 50% /*rtl:ignore*/;
    bottom: 100%;
  }
}

// -----

.aprs-target,
.aprs-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: none;
    user-select: none;
    box-sizing: border-box;
}

.aprs-target {
    height: 2px;
    position: relative;
    background-color: #ccc;
}

.aprs-base,
.aprs-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: @zindex-hack;
}

.aprs-connects {
  overflow: hidden;
  z-index: @zindex-zero;
}

.aprs-connect,
.aprs-origin {
  will-change: transform;
  position: absolute;
  z-index: @zindex-hack;
  top: 0;
  right: 0 /*rtl:ignore*/;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0/*rtl:ignore*/;
  transform-style: flat;
}

.aprs-connect {
    height: 100%;
    width: 100%;
}

.aprs-origin {
  height: 10%;
  width: 10%;
}

.aprs-connect {
  background-color: @sui_color_main;
}

.aprs-horizontal .aprs-origin {
  height: 0;
}

.aprs-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.aprs-handle {
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16) /*rtl:ignore*/;
}

.aprs-active {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16) /*rtl:ignore*/;
}

.aprs-base .aprs-handle>.aprs-touch-area {
  height: 1.2rem;
  width: 1.2rem;
  position: relative;
  top: -0.3rem;
}
.aprs-base .aprs-handle-upper>.aprs-touch-area {
  .left(-.6rem);
}

.slider-range {
    flex: 1;
    &__prices {
        .clearfix();
    }

    &__prices-lower {
        float: left /*rtl:ignore*/;
    }

    &__prices-upper {
        float: right /*rtl:ignore*/;
    }

    &__wrapper {
        padding: 0.4rem 0.2rem 0.41rem 0.4rem;
    }
}
.slider-range__prices>.slider-range__prices-upper {
    margin-right: 0.3rem /*rtl:ignore*/;
}

[disabled] .aprs-connect {
    background-color: #ccc;
}

[disabled].aprs-target,
[disabled].aprs-handle,
[disabled] .aprs-handle {
    cursor: not-allowed;
}

.aprs-horizontal {
    height: 2px;
    width: 96%;
}

.aprs-horizontal .aprs-handle {
    width: 0.6rem;
    height: 0.6rem;
    right: -0.3rem /*rtl:ignore*/;
    border-radius: 50%;
    top: -0.3rem;
}

.noUi-touch-area {
    height: 100%;
    width: 100%;
}

.aprs-tooltip {
    display: block;
    position: absolute;
    color: #000;
    text-align: center;
    white-space: nowrap;
}

.aprs-horizontal {
    .aprs-tooltip {
        transform: translate(-50%, 0) /*rtl:ignore*/;
        left: 50% /*rtl:ignore*/;
        bottom: 100%;
    }
}

.slider-range__input {
  display: flex;
  margin-bottom: 8px;

  >div {
    display: flex;
    justify-self: center;
    align-items: center;
  }

  .slider-range__input-left,
  .slider-range__input-right {
    padding: 6px 12px;
    flex-grow: 1;
    gap: 4px;
    border: 0.436px solid var(--sui-color-gray-weak-1, #E5E5E5);

    span {
      font-size: 12px;
      font-weight: 400;
      color: #666;
    }

    input {
      border: none;
      outline: none;
      font-size: 12px;
      color: #000;
      text-align: center;
      line-height: normal;
      display: block;
      flex-grow: 1;
    }
  }

  .slider-range__input-to {
    padding: 0 12px;
    gap: 12px;
  }
}

.slider-range__input-rtl {
  flex-direction: row-reverse;
}

.slider-range-new {
  .slider-range__prices-upper {
    margin-right: 0;
  }
  .slider-range__prices-lower,
  .slider-range__prices-upper,
  .slider-range__input-default input {
    color: #999;
  }

  .aprs-horizontal {
    height: 1px;
  }

  .slider-range__wrapper {
    padding-left: 0 ;
    padding-right: 0 ;

    .apollo-slider {
      width: 100%;
      padding: 0 11 / 37.5rem;
    }
  }

  .aprs-horizontal .aprs-handle {
    width: 24 / 37.5rem;
    height: 24 / 37.5rem;
    background-color: #fff;
    background-image: url(/pwa_dist/images/filter/price-slide-button-d95c9652ea.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-color: transparent;
  }
}
</style>
